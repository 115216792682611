import Axios from "axios"

// const ENDPOINT = 'http://localhost:5643'
const ENDPOINT = 'https://api.anita-delivery.com'
const ANITA_AUTHENTICATION_TOKEN = "anita_authentication_token"

export class Anita {
  constructor() {
    this.api = Axios.create({
      baseURL: ENDPOINT,
      headers: { "X-Anita-Platform": 5 }
    })

    const token = this.authenticationToken

    if (token) {
      this.api.defaults.headers.common["Authorization"] = `Bearer ${token}`
    }
  }

  get authenticationToken() {
    return localStorage.getItem(ANITA_AUTHENTICATION_TOKEN)
  }

  set authenticationToken(token) {
    if (!token) {
      localStorage.removeItem(ANITA_AUTHENTICATION_TOKEN)
    } else {
      localStorage.setItem(ANITA_AUTHENTICATION_TOKEN, token)
    }
  }

  async getAccount() {
    if (!this.authenticationToken) {
      return undefined
    }

    try {
      const response = await this.api.get("/accounts/me")
      return response.data.data.account
    } catch (error) {
      console.log("Error getting account", error)
    }

    return undefined
  }

  async logIn(email, password) {
    const response = await this.api.post(
      "/accounts/log-in", 
      { email, password }
    )

    if (response.data.errors) {
      throw new Error(response.data.errors[0].msg)
    }

    const { token } = response.data.data
    this.authenticationToken = token

    this.api.defaults.headers.common["Authorization"] = `Bearer ${token}`
  
    return response.data.data
  }

  async logOut() {
    try {
      await this.api.post("/accounts/log-out")
    } finally {
      this.authenticationToken = undefined

      delete this.api.defaults.headers.common["Authorization"]
    }
  }

  async connectStripeAccount(code) {
    const response = await this.api.post(
      "/drivers/connect-stripe", 
      { code }
    )

    if (response.data.errors) {
      throw new Error(response.data.errors[0].msg)
    }
  }

  async hasConnectedStripe() {
    const response = await this.api.get(
      "/drivers/stripe-status"
    )

    if (response.data.errors) {
      throw new Error(response.data.errors[0].msg)
    }

    return response.data.data.hasConnectedStripe
  }
}
