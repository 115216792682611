import React, { useState, useContext } from "react"

import { AppContext } from "../Context"

import "./AccountPage.css"

export function AccountPage() {
  const { account } = useContext(AppContext)
  const [formData, setFormData] = useState({ 
    currentPassword: "",
    newPassword: "", 
    confirmNewPassword: "" 
  })
  const [isChangingPassword, setChangingPassword] = useState(false)

  function onInputChange(event) {
    const newFormData = { 
      ...formData, 
      [event.target.name]: event.target.value 
    }

    setFormData(newFormData)
  }

  function closeForm() {
    setFormData({
      currentPassword: "",
      newPassword: "", 
      confirmNewPassword: "" 
    })
    setChangingPassword(false)
  }

  async function onSubmit(event) {
    event.preventDefault()

    // try {
    //   const { account } = await api.logIn(formData.email, formData.password)

    //   if (account.driver == null) {
    //     history.push("/log-out")
    //     return
    //   }

    //   app.setAccount(account)
    //   history.push("/account")
    // } catch (error) {
    //   alert("Please try again later.")
    //   console.log("Error logging in", error)
    // }
  }

  const hasConnectedStripe = account && account.hasConnectedStripe
  let driverStatus = ``

  if (account && account.driver) {
    const { approvedAt, declinedAt, appliedAt } = account.driver    

    driverStatus = approvedAt
      ? `Approved - ${new Date(approvedAt).toDateString()}`
      : declinedAt
        ? `Declined - ${new Date(declinedAt).toDateString()}`
        : appliedAt
          ? `Application submitted - ${new Date(appliedAt).toDateString()}`
          : `N/A`
  }

  return (
    <div className="Page">
      <h1 style={{ marginBottom: 32 }}>Your account</h1>
      {account &&
        <>
          <div className="TextField">
            <label>Full name</label>
            <div>{account.firstName} {account.lastName}</div>
          </div>
          <div className="TextField" style={{ marginTop: 16 }}>
            <label>Phone number</label>
            <div>{account.phoneNumber}</div>
          </div>
          <div className="TextField" style={{ marginTop: 16 }}>
            <label>Email address</label>
            <div>{account.email}</div>
          </div>
          <div className="TextField" style={{ marginTop: 16 }}>
            <label>Vehicle registration</label>
            <div>{account.driver.vehicleRegistration || "N/A"}</div>
          </div>
          <div className="TextField" style={{ marginTop: 16 }}>
            <label>Driver status</label>
            <div>{driverStatus}</div>
          </div>
          <div className="TextField" style={{ marginTop: 16, marginBottom: 16 }}>
            <label>Stripe account</label>
            <div>{hasConnectedStripe ? 'Connected' : 'Not connected'}</div>
          </div>
          {/* {!isChangingPassword &&
            <div 
              className="Button" 
              style={{ marginTop: 16 }}
              onClick={() => setChangingPassword(true)}
            >
              Change password
            </div>
          } */}
        </>
      }
      {isChangingPassword &&
        <>
          <h2 style={{ marginTop: 16, marginBottom: 32 }}>Change your password</h2>
          <form onSubmit={onSubmit} style={{ marginBottom: 64 }}>
            <div className="TextField">
              <label>Current password</label>
              <input
                name="currentPassword"
                value={formData.currentPassword}
                onChange={onInputChange}
                type="password"
                required
                placeholder="Enter your current password"
              />
            </div>
            <div className="TextField">
              <label>New password</label>
              <input
                name="newPassword"
                value={formData.newPassword}
                onChange={onInputChange}
                type="password"
                required
                placeholder="Enter your new password"
              />
            </div>
            <div className="TextField">
              <label>Confirm new password</label>
              <input
                name="confirmNewPassword"
                value={formData.confirmNewPassword}
                onChange={onInputChange}
                type="password"
                required
                placeholder="Enter your new password again"
              />
            </div>
            <button type="submit">Submit</button>
            <button 
              type="reset"
              onClick={closeForm}
            >
              Cancel
            </button>
          </form>
        </>
      }
    </div>
  )
}