import React, { useContext, useEffect, useState } from "react"
import { Redirect, useLocation } from "react-router-dom"
import QueryString from "query-string"

import { Anita } from "../lib/Anita"

import { AppContext } from "../Context"

export function ConnectStripePage() {
  const { setAccount } = useContext(AppContext)
  const [isLoading, setLoading] = useState(true)
  const location = useLocation()

  useEffect(
    () => {
      async function connectStripeAccount() {
        const { code } = QueryString.parse(location.search)
        const api = new Anita()
        
        try {
          await api.connectStripeAccount(code)
        } catch (error) {
          console.log("Error connecting Stripe account", code, error)
          return
        }

        const updatedAccount = await api.getAccount()
        updatedAccount.hasConnectedStripe = true

        setAccount(updatedAccount)
        setLoading(false)
      }
      
      connectStripeAccount()
    }, 
    [location.search, setAccount]
  )

  return (
    <div className="Page">
      <h1>Please wait</h1>
      <p>We're connecting your Stripe account, hold tight...</p>
      {!isLoading && <Redirect to="/" />}
    </div>
  )
}