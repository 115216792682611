import React, { useContext } from "react"
// import { Redirect } from "react-router-dom"

import { AppContext } from "../Context"

function UnauthenticatedContent() {
  return (
    <>
      <h1>Manage your account</h1>
      <p><a href="/log-in">Log in</a> to view the status of your application, change your password and more. <span role="img" aria-label="manage">👩‍💻</span></p>
    </>
  )
}

function ApplicationDeclinedContent() {
  return (
    <>
      <h1>Application declined</h1>
      <p>Unfortunately your driver application has been declined.</p>
      <h2 style={{ marginTop: 32, marginBottom: 16 }}>What's next?</h2>
      <p>You'll receive an email from us soon containing more information on why we came to this decision. We'll keep your application on record and notify you in the future when you're eligible to reapply.</p>
      <h3 style={{ marginTop: 32 }}>Thank you for your time and interest</h3>
      <p>If you have any questions or feedback please contact us at <a href="mailto:hello@anita.delivery">hello@anita.delivery</a>.</p>
    </>
  )
}

// Live
const STRIPE_CLIENT_ID = "ca_FF0G7tXFmssM2NCqtOZAf5zuPmOuBIiH"
const STRIPE_CONNECT_URL = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${STRIPE_CLIENT_ID}&scope=read_write&redirect_uri=https://drivers.anita-delivery.com/connect-stripe`
// Test
// const STRIPE_CLIENT_ID = "ca_FF0G83HlFZImwtKDXReCBHlVddfO4jpk"
// const STRIPE_CONNECT_URL = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${STRIPE_CLIENT_ID}&scope=read_write&redirect_uri=http://localhost:3000/connect-stripe`

function ApplicationApprovedContent() {
  return (
    <>
      <h1>Application approved</h1>
      <p>Your driver application has been approved. Welcome aboard! <span role="img" aria-label="tada">🎉</span></p>
      <h2 style={{ marginTop: 32, marginBottom: 16 }}>What's next?</h2>
      <p>All of our payments are processed by <span className="bold">Stripe</span>. You must create a <span className="bold">free</span> Stripe account and connect it to your Anita Delivery account to receive payments.</p>
      <p style={{ marginTop: 32 }}>Your Stripe account is linked to your bank account and any payments you receive will be <span className="bold">automatically deposited into your bank account on a daily basis</span>.</p>
      <p style={{ marginTop: 32 }}>Creating a Stripe account should only take a few minutes and you'll be ready to <span className="bold">start delivering once you're done</span>.</p>
      <a
        href={STRIPE_CONNECT_URL}
        // href="/connect-stripe"
        className="button"
        style={{ margin: 32 }}
      >
        Connect a Stripe account
      </a>
    </>
  )
}

function ApplicationSubmittedContent() {
  return (
    <>
      <h1>Waiting for review</h1>
      <p>We've received your application and we'll be reviewing it shortly. <span role="img" aria-label="application">📬</span></p>
    </>
  )
}

function NoApplicationContent() {
  return (
    <>
      <h1>Apply to become a driver</h1>
      <p>Download <span style={{ fontWeight: "600" }}>Anita Driver</span> from the App Store (iOS) or Google Play (Android) to apply to become a driver. <span role="img" aria-label="app">📱</span></p>
    </>
  )
}

function ConnectedStripeContent() {
  return (
    <>
      <h1>You're all set!</h1>
      <p>You're now ready to start delivering! All you need to do is open the app on your phone and set your status to online. <span role="img" aria-label="car">🚗</span></p>
    </>
  )
}

export function HomePage() {
  const app = useContext(AppContext)
  const isAuthenticated = app.account != null
  const hasApplied = isAuthenticated && app.account.driver.appliedAt != null
  const isApproved = isAuthenticated && app.account.driver.approvedAt != null
  const isDeclined = isAuthenticated && app.account.driver.declinedAt != null
  const hasConnectedStripe = isAuthenticated && app.account.hasConnectedStripe

  // if (isAuthenticated && app.account.driver.approvedAt && app.account.hasConnectedStripe) {
  //   return <Redirect to="/account" />
  // }

  return (
    <div className="Page">
      {
        !isAuthenticated
          ? <UnauthenticatedContent />
          : hasConnectedStripe
            ? <ConnectedStripeContent />
            : isDeclined
              ? <ApplicationDeclinedContent />
              : isApproved
                ? <ApplicationApprovedContent />
                : hasApplied
                  ? <ApplicationSubmittedContent />
                  : <NoApplicationContent />
      }
    </div>
  );
}