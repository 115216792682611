import React, { useState, useEffect } from "react"
import { BrowserRouter } from "react-router-dom"
import { Anita } from "./lib/Anita"

import { AppContext } from "./Context"

import { AppContainer } from "./AppContainer"

import "./App.css"

function App() {
  const [account, setAccount] = useState()

  useEffect(
    () => {
      async function getAccount() {
        const api = new Anita()
        const account = await api.getAccount()

        if (!account) {
          return
        }

        const hasConnectedStripe = await api.hasConnectedStripe()
        account.hasConnectedStripe = hasConnectedStripe

        setAccount(account)
      }

      getAccount()
    },
    []
  )

  const appState = {
    account, setAccount
  }

  return (
    <BrowserRouter>
      <AppContext.Provider value={appState}>
        <AppContainer />
      </AppContext.Provider>
    </BrowserRouter>
  )
}

export default App
