import React, { useContext, useEffect } from "react"
import { Redirect } from "react-router-dom"
import { Anita } from "../lib/Anita"

import { AppContext } from "../Context"

export function LogOutPage() {
  const app = useContext(AppContext)
  app.setAccount(undefined)

  useEffect(
    () => {
      async function logOut() {
        const api = new Anita()
        await api.logOut()
      }
      
      logOut()
    }, 
    []
  )

  return <Redirect to="/"/>
}