import React, { useContext } from "react"
import { Switch, Route } from "react-router-dom"

import { AppContext } from "./Context"

import { NavMenu } from "./components/NavMenu"

import { HomePage } from "./pages/HomePage"
import { LogInPage } from "./pages/LogInPage"
import { LogOutPage } from "./pages/LogOutPage"
import { ConnectStripePage } from "./pages/ConnectStripePage"
import { AccountPage } from "./pages/AccountPage"

import "./AppContainer.css";

export function AppContainer() {
  const { account } = useContext(AppContext)
  const isAuthenticated = account != null
  // const hasFinishedApplication = (
  //   isAuthenticated && 
  //   account.driver.approvedAt && 
  //   account.hasConnectedStripe
  // )

  const defaultLinks = [
    { title: "Home", to: "/" },
    { title: "Log in", to: "/log-in" }
  ]
  
  let authenticatedLinks = [
    { title: "Your application", to: "/" },
    { title: "Your account", to: "/account" },
    { title: "Log out", to: "/log-out" }
  ]

  // if (!hasFinishedApplication) {
  //   authenticatedLinks = [
  //     { title: "Your application", to: "/" },
  //     ...authenticatedLinks
  //   ]
  // }

  return (
    <div className="AppContainer">
      <div className="Header">
        <div className="Logo">Anita Delivery - Drivers</div>
        <NavMenu links={isAuthenticated ? authenticatedLinks : defaultLinks} />
      </div>
      <div className="Content">
        <Switch>
          <Route path="/log-out">
            <LogOutPage />
          </Route>
          <Route path="/log-in">
            <LogInPage />
          </Route>
          <Route path="/connect-stripe">
            <ConnectStripePage />
          </Route>
          <Route path="/account">
            <AccountPage />
          </Route>
          <Route path="/">
            <HomePage />
          </Route>
        </Switch>
      </div>
    </div>
  )
}
