import React, { useState, useContext } from "react"
import { useHistory } from "react-router-dom"
import { Anita } from "../lib/Anita"

import { AppContext } from "../Context"

import "./LogInPage.css"

export function LogInPage() {
  const app = useContext(AppContext)
  const history = useHistory()
  const [formData, setFormData] = useState({ email: "", password: "" })
  const api = new Anita()

  function onInputChange(event) {
    const newFormData = { 
      ...formData, 
      [event.target.name]: event.target.value 
    }

    setFormData(newFormData)
  }

  async function onSubmit(event) {
    event.preventDefault()

    try {
      const { account } = await api.logIn(formData.email, formData.password)

      if (account.driver == null) {
        history.push("/log-out")
        return
      }

      const hasConnectedStripe = await api.hasConnectedStripe()
      account.hasConnectedStripe = hasConnectedStripe

      app.setAccount(account)

      const isApproved = account.driver.approvedAt && hasConnectedStripe

      history.push(!isApproved ? "/" : "/account")
    } catch (error) {
      alert(error.message)
      console.log("Error logging in", error)
    }
  }

  return (
    <div className="Page">
      <h1>Log in</h1>
      <form onSubmit={onSubmit}>
        <div className="TextField">
          <label>Email address</label>
          <input
            name="email"
            value={formData.email}
            onChange={onInputChange}
            type="email"
            required
            placeholder="Enter your email address"
          />
        </div>
        <div className="TextField">
          <label>Password</label>
          <input
            name="password"
            value={formData.password}
            onChange={onInputChange}
            type="password"
            required
            placeholder="Enter your password"
          />
        </div>
        <button>Continue</button>
      </form>
    </div>
  )
}