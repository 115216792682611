import React from "react"
import { useHistory, useLocation } from "react-router-dom"

import "./NavMenu.css"

export function NavMenu({ links }) {
  const location = useLocation()
  const history = useHistory()

  const linkItems = links.map(link => (
    <div
      className={`Link${location.pathname === link.to ? ' LinkActive' : ''}`}
      onClick={() => history.push(link.to)}
      key={link.to}
    >
      {link.title}
    </div>
  ))

  return (
    <div className="NavMenu">
      {linkItems}
    </div>
  )
}
